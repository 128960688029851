import {
  useAndReportExperimentTreatment,
  useExperimentTreatmentWithoutReporting,
} from '@ms/yammer-hooks/dist/experiments';
import { useIsTopLevelFeaturePage } from '@ms/yammer-hooks/dist/navigation';
import { useReportPageLoadedFromLinkClick } from '@ms/yammer-hooks/dist/pageLoad';
import { useIsLegacyPWABlocked } from '@ms/yammer-hooks/dist/pwa';
import { useTranslations } from '@ms/yammer-i18n-react';
import { useEndActiveSession } from '@ms/yammer-search-components';
import CommonStrings from '@ms/yammer-strings/dist/Common';
import PanesStrings from '@ms/yammer-strings/dist/Panes';
import { MultiColumnAppLayout } from '@ms/yammer-web-core-ui/dist/components/AppLayout';
import { articleBasePath, articleEditPageMatchPath, articlePageMatchPath } from '@ms/yammer-web-support/dist/urls';
import React, { FC } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import LegacyPWABlocked from '../PWA/Legacy/LegacyPWABlocked';
import SuiteHeader from '../SuiteHeader';
import { VivaAppBar } from '../VivaAppbar';

import { AppLeftNav } from './AppLeftNav';
import ArticleFooter from './LoadableArticleFooter';
import LoadableWebSubHeader from './LoadableWebSubHeader';
import { useGetBannersCallback } from './hooks';

const AppLayout: FC = ({ children }) => {
  const { pathname } = useLocation();
  useReportPageLoadedFromLinkClick(pathname);
  useEndActiveSession();

  /**
   * This custom Article logic is temporary until we refactor CoreUI's AppLayout so
   * content/page-level templates allow slot props like `footer`.
   * https://dev.azure.com/yammer/engineering/_workitems/edit/295328
   */
  const disableLeftNavForArticles = useExperimentTreatmentWithoutReporting('ArticleWithLeftNav') !== true;
  const isAnyArticlesPage = !!useRouteMatch(articleBasePath);
  const isArticleEditPage = !!useRouteMatch({ path: articleEditPageMatchPath, exact: true });
  const isArticleThreadPage = !!useRouteMatch(articlePageMatchPath) && !isArticleEditPage;
  const backgroundColor = isAnyArticlesPage ? 'white' : undefined;
  const isLeftSidebarDisabled = isAnyArticlesPage && disableLeftNavForArticles;
  const footer = isArticleThreadPage ? <ArticleFooter /> : undefined;

  const banners = useGetBannersCallback()();

  const leftNav = isLeftSidebarDisabled ? undefined : <AppLeftNav />;

  const openAriaLabel = useTranslations(PanesStrings).showAdditionalInformationPane();
  const closeAriaLabel = useTranslations(CommonStrings).commonClose();

  const isVivaAppBarEnabled = !!useAndReportExperimentTreatment('VivaAppBar');

  const isTopLevelFeaturePage = useIsTopLevelFeaturePage();

  const outerLeftNav = isVivaAppBarEnabled ? <VivaAppBar /> : undefined;

  const isLegacyPWABlocked = useIsLegacyPWABlocked();
  if (isLegacyPWABlocked) {
    return (
      <MultiColumnAppLayout openContentSidebarAriaLabel={openAriaLabel} closeContentSidebarAriaLabel={closeAriaLabel}>
        <LegacyPWABlocked />
      </MultiColumnAppLayout>
    );
  }

  return (
    <MultiColumnAppLayout
      header={<SuiteHeader />}
      banner={banners}
      subHeader={<LoadableWebSubHeader />}
      hasHeroBackground={isTopLevelFeaturePage}
      outerLeftNav={outerLeftNav}
      leftNav={leftNav}
      openContentSidebarAriaLabel={openAriaLabel}
      closeContentSidebarAriaLabel={closeAriaLabel}
      footer={footer}
      backgroundColor={backgroundColor}
    >
      {children}
    </MultiColumnAppLayout>
  );
};

export default AppLayout;
